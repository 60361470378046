import styled, {css} from "styled-components"
import {H3, H4, mincho} from '../styles/text.js'
import takeoutMenu from '../data/takeout_menu.json'
import lunchTakeoutMenu from '../data/lunch_takeout_menu.json'
import partyMenu from '../data/party_menu.json'
import MenuItem from "./MenuItem";
import takeoutImg from '../images/common/takeout_food.png'
import kamonImg from '../images/common/kamon@3x.png'
import color from "../styles/color";

function TakeOutMenu() {
    return (<Section id="takeout-menu">
        <H2>お持ち帰りメニュー</H2>
        <div>
            <MenuList>
                {
                    Object.keys(takeoutMenu).map(key => {
                        const item = takeoutMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
        </div>

        <div>
            <H3>パーティー用盛り合わせ<small>ご予算に応じます</small></H3>
            <MenuList>
                {
                    Object.keys(partyMenu).map(key => {
                        const item = partyMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
        </div>
    </Section>);
}

export default TakeOutMenu;

const Section = styled.section`
`

const H2 = styled.h2`
  ${mincho}
  text-align: center;
  font-size: 2em;
  border-top: dashed ${color.primary} 1px;
  padding: 10px;
  margin-bottom: 0;
  &:before {
    content:'';
    display: block;
    background: url(${takeoutImg})  no-repeat center center;
    margin: 24px auto;
    width:98px;
    height:61px;
  }
  &:after {
    content:'';
    display: block;
    background: center center no-repeat url(${kamonImg}) ;
    background-size: 24px 24px;
    margin: 24px auto 0;
    width:24px;
    height:24px;
  }
`

const Item = styled.div`
  border-bottom: 1px dashed #603813;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
`

const MenuList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
`
import styled from "styled-components";
import {H2, mincho} from "../styles/text";
import color from "../styles/color";

function GlobalAlert() {
    return <Section id="global-alert">
        <Title>閉店のお知らせ</Title>
        <Content>
            <div>
                <p>長らくご愛顧いただきましたが　<u>2024年4月18日（木）</u>　をもちまして閉店することとなりました。</p>
                <p>昭和48年開店以来　51年にわたり賜りましたご厚情　誠にありがとうございました。</p>
                <p>従業員一同　心より厚く御礼申し上げます</p>
                <Signature>きっちんすず木　店主</Signature>
            </div>
        </Content>
    </Section>;
}

export default GlobalAlert;

const Section = styled.div`
  margin: 1em;
  border: solid 1px #8c6239;
  border-radius: 1em;
  background: white;
  padding: 1em;
  max-width: 640px;
`

const Title = styled.h3`
  margin: 0 1em .5em;
  padding: 0 0 .5em;
  ${mincho}
  font-weight: bold;
  border-bottom: 1px dotted ${color.secondary};
  text-align: center;
`

const Preamble = styled.p``

const Content = styled.div``

const Gcal = styled.iframe`
  border: 0;
  width: 100%;
`


const Signature = styled.p`
  text-align: right;
`
import {createGlobalStyle, css} from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fcfbf5;
  }
`

/* @include css-gradient(#dfdfdf,#f8f8f8); */
export const css_gradient = (from = "#dfdfdf", to = "#f8f8f8") => css`
  background-color: ${to};
  background-image: linear-gradient(to bottom, ${from}, ${to});
`;

export const wood_gradient = css`
  background: linear-gradient(0deg, rgba(140, 98, 57, 1) 0%, rgba(105, 68, 29, 1) 9.65%, rgba(116, 75, 35, 1) 10.64%, rgba(128, 81, 38, 1) 38.61%, rgba(132, 78, 31, 1) 100%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 1);
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color='#000000') progid:DXImageTransform.Microsoft.gradient(startColorstr='#844E1F', endColorstr='#8C6239', GradientType=0);
`;

export const shadow = css`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
`
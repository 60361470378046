import styled from "styled-components"
import {mincho} from "../styles/text";
import color from "../styles/color";
import logo from '../images/common/logo@3x.png';
import kamon from '../images/common/kamon@3x.png';
import {shadow, wood_gradient} from "../styles/global";
import AnchorLink from "react-anchor-link-smooth-scroll";

function Header() {
    return (<HeaderBase id="top">
        <Wrap>
            <Content>
                <Logo>
                    <img src={logo} alt="とんかつすず木" height="60"/>
                </Logo>
                <Kamon>
                    <img src={kamon} alt="img" height="29"/>
                </Kamon>
                <PageMenu>
                    <ul>
                        <li>
                            <AnchorLink href="#opening-hours">営業時間</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink href="#access">アクセス</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink href="#food-menu">料理メニュー</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink href="#takeout-menu">お持ち帰りメニュー</AnchorLink>
                        </li>
                    </ul>
                </PageMenu>
            </Content>
            <ToTop href="#top">TOPへ</ToTop>
        </Wrap>
    </HeaderBase>);
}

export default Header;

const HeaderBase = styled.header`
  background: ${color.cream};

  &::before {
    content: '';
    display: block;
    height: 16px;
    ${wood_gradient}
    ${shadow}
  }
`

const ToTop = styled(AnchorLink)`
  display: block;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  font-weight: bold;
  text-decoration: none;
  color: ${color.white};
  padding: .5em .8em;
  background-color: ${color.primary};
  &:visited, &:hover, &:active {
    color: ${color.white}
  }
`

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const Content = styled.div`
  margin: 16px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 10px auto;
  }

  @media (min-width: 640px) {
    flex-direction: row;
    flex-wrap: nowrap;

    div {
      margin: 0;
    }
  }
`

const Logo = styled.div`

`

const Kamon = styled.div`
  img {
    width: 24px;
    height: 24px;
    @media (min-width: 800px) {
      width: 29px;
      height: 29px;
    }
  }
`

const PageMenu = styled.div`
  ul {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-content: center;
    @media (min-width: 500px) {
      flex-direction: row;
    }
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      ${mincho}
      display: block;
      width: 100%;
      color: #1a1a1a;
      text-decoration: none;
      text-align: center;
      font-weight: bold;
      margin: 0;
      padding: .2em .75em;
      box-sizing: border-box;
    }

    @media (min-width: 500px) {
      li:nth-child(n+2) a {
        border-left: solid ${color.black} 1px;
      }

      li:last-child a {
        padding-right: 0;
      }
    }
  }
`
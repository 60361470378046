import styled, {css} from "styled-components";
import color from "./color";
import tsukemonoImg from '../images/common/tsukemono@3x.png';
import gohanImg from '../images/common/gohan@3x.png';
import tonkatsuImg from '../images/common/tonkatsu@3x.png';

export const mincho = css`
  font-family: Georgia, 'Hiragino Mincho ProN', 'Yu Mincho', serif;
`

export const gothic = css`
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'Yu Gothic', sans - serif;
`

export const code = css`
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
`

const subtitle = css`
  @media (max-width: 639px) {
    display: block;
  }
  @media (min-width: 640px) {
    margin-left: 1em;
  }
  ${gothic}
  font-weight: normal;
  color: ${color.dark_gray};
`

export const H2 = styled.h2`
  ${mincho}
  font-weight: bold;
  border-bottom: 2px solid ${color.secondary};
  background-image: url(${tonkatsuImg});
  background-position: left center;
  background-size: auto 1em;
  background-repeat: no-repeat;
  padding: .5em 0 .5em 2em;
  margin-bottom: 0.5em;

  small {
    ${subtitle}
  }
`

export const H3 = styled.h3`
  ${mincho}
  font-weight: bold;
  color: ${color.primary};
  background-image: url(${gohanImg});
  background-position: left center;
  background-size: auto 1em;
  background-repeat: no-repeat;
  padding: .5em 0 .5em 2.2em;
  margin-bottom: 0.5em;

  small {
    ${subtitle}
  }
`;

export const H4 = styled.h4`
  ${mincho}
  color: ${color.primary};
  font-weight: bold;
  background-image: url(${tsukemonoImg});
  background-position: left center;
  background-size: auto 1em;
  background-repeat: no-repeat;
  padding: .5em 0 .5em 2.2em;
  margin-bottom: 0.5em;

  small {
    ${subtitle}
  }
`;

export const H5 = styled.h5`
  ${mincho}
  color: ${color.primary};
  font-weight: bold;
  margin-bottom: 0.5em;
`;

export default class text {
}
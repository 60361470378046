import styled from "styled-components";
import {H2, H4} from '../styles/text.js'

function OpeningHours() {
    return <Section id="opening-hours">
        <H2>定休日／営業時間</H2>
        <Content>
            <div>
                <H4>定休日</H4>
                <p>毎週火曜日、第3月曜日</p>
                <p><small>※第3月曜日が祝日の場合は第3水曜日に振り替え</small></p>
                <H4>営業時間</H4>
                <ul>
                    <li>昼：11:00～14:00 (L.O. 13:45)</li>
                    <li>夜：17:00～20:30 (L.O. 20:00)</li>
                </ul>
            </div>
            <div>
                <H4>営業カレンダー</H4>
                <Gcal
                    src="https://calendar.google.com/calendar/embed?src=i80tcq2i4e1rojhu5e7oq2q140%40group.calendar.google.com&ctz=Asia%2FTokyo&showTabs=0&showPrint=0&showTitle=0"
                    height="320" frameBorder="0" scrolling="no"/>
            </div>
        </Content>
    </Section>;
}

export default OpeningHours;

const Section = styled.div``

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    flex: 10 300px;
  }
`

const Gcal = styled.iframe`
  border: 0;
  width: 100%;
`
import style from './App.module.scss';
import Header from "./component/Header";
import Footer from "./component/Footer";
import {Helmet} from "react-helmet";
import Main from "./component/Main";
import Hero from "./component/Hero";
import GlobalAlert from "./component/GlobalAlert";

function App() {
    // 創業
    // const from = new Date(1972, 4, 1).getTime();
    // const duration = (new Date().getTime() - from);
    // const year = Math.floor(duration / (1000 * 60 * 60 * 24 * 365))
    // const title = "とんかつすず木 | 創業" + year + "年 愛知県弥富のおいしいとんかつ屋"
    const title = "とんかつすず木 | 創業50年 愛知県弥富のおいしいとんかつ屋"

    return (
        <div className={style.base}>
            <Helmet title={`${title}`} />
            <Header/>
            <Hero/>
            <Main className={style.main}/>
            <Footer/>
        </div>
    );
}

export default App;
import styled, {css} from "styled-components"
import {H2, H3, H4, mincho} from '../styles/text.js'
import takeoutImg from '../images/common/takeout@3x.png'

function MenuItem({name, price, takeout, note}) {
    return (
        name ? (
        <Item>
        <Content>
            <Name>{name}</Name>
            <Price>{price}</Price>
            {/*{takeout &&*/}
            {/*    (*/}
            {/*        <TakeOut src={takeoutImg} width="57" height="16" alt="持ち帰りOK"/>*/}
            {/*    )}*/}
        </Content>
        {note && (
            <Note>{note}</Note>
        )}
    </Item>) : <EmptyItem/> );
}

export default MenuItem;

const EmptyItem = styled.div`
  flex: 1 0 300px;
  margin: 0 5px;
  padding: 5px;
  min-width: 300px;
`

const Item = styled(EmptyItem)`
  border-bottom: 1px dashed #603813;
`

const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`

const Name = styled.div`
  ${mincho}
  font-weight: bold;
  flex: 0 1 auto;
  margin-right: 5px;
`

const TakeOut = styled.img`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  flex: 0 1 auto;
`

const Price = styled.div`
  flex: 1 0 auto;
  padding: 0 5px;
  text-align: right;
  font-size: 95%;
`

const Note = styled.div`
  font-weight: normal;
  font-size: 80%;
`
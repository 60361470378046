import styled, {keyframes} from "styled-components";
import heroImg from '../images/top/web_hero_2.jpg';

function Hero() {
    return <HeroBase />
}

export default Hero;

const heroScroll = keyframes`
    from {
        background-position: 0 0;
    }
    to {
        background-position: -1849px 0;
    }
`

const HeroBase = styled.div`
    display: block;
    position: relative;
    height: 400px;
    width: 100%;
    background-image: url(${heroImg});
    background-size: 1340px auto;
    background-repeat: repeat-x;
    animation: ${heroScroll} 60s linear infinite;
`
import styled, {css} from "styled-components"
import {H3, H4, mincho} from '../styles/text.js'
import setMenu from '../data/set_menu.json'
import grandMenu from '../data/grand_menu.json'
import lunchMenu from '../data/lunch_menu.json'
import riceMenu from '../data/rice_menu.json'
import sideMenu from '../data/side_menu.json'
import drinkMenu from '../data/drink_menu.json'
import MenuItem from "./MenuItem";
import setImg from '../images/common/teishoku@3x.png'
import kamonImg from '../images/common/kamon@3x.png'
import color from "../styles/color";

function FoodMenu() {
    return (<Section id="food-menu">
        <H2>料理メニュー</H2>
        <div>
            <H3>定食</H3>
            <MenuList>
                {
                    Object.keys(setMenu).map(key => {
                        const item = setMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
        </div>

        <div>
            <H3>ランチ<small>14:00 まで</small></H3>
            <MenuList>
                {
                    Object.keys(lunchMenu).map(key => {
                        const item = lunchMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
        </div>

        <div>
            <H3>一品メニュー</H3>
            <MenuList>
                {
                    Object.keys(grandMenu).map(key => {
                        const item = grandMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
            <H4>ご飯物</H4>
            <MenuList>
                {
                    Object.keys(riceMenu).map(key => {
                        const item = riceMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
            <H4>おつまみ</H4>
            <MenuList>
                {
                    Object.keys(sideMenu).map(key => {
                        const item = sideMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
            <H4>飲み物</H4>
            <MenuList>
                {
                    Object.keys(drinkMenu).map(key => {
                        const item = drinkMenu[key]
                        return <MenuItem name={item.name} price={item.price} takeout={item.takeOut} note={item.note}/>
                    })
                }
            </MenuList>
        </div>
    </Section>);
}

export default FoodMenu;

const Section = styled.section`
`

const H2 = styled.h2`
  ${mincho}
  text-align: center;
  font-size: 2em;
  border-top: dashed ${color.primary} 1px;
  padding: 10px;
  margin-bottom: 0;
  &:before {
    content:'';
    display: block;
    background: center center no-repeat url(${setImg}) ;
    background-size: 200px 107px;
    margin: 24px auto;
    width:200px;
    height:107px;
  }
  &:after {
    content:'';
    display: block;
    background: center center no-repeat url(${kamonImg}) ;
    background-size: 24px 24px;
    margin: 24px auto 0;
    width:24px;
    height:24px;
  }
`

const Item = styled.div`
  border-bottom: 1px dashed #603813;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
`

const MenuList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
`
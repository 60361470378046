import styled from "styled-components"
import {H2, H4} from '../styles/text.js'
import mapImage from '../images/common/map@3x.png';

function Access() {
    return <Section id="access">
        <H2 id="menu-access">アクセス</H2>
        <div>
            <H4>所在地</H4>
            〒498-0006 愛知県弥富市佐古木１丁目３５
        </div>
        <Content>
            <div>
                <H4>アクセスマップ</H4>
                <a href={mapImage}>
                    <Map src={mapImage} alt="アクセスマップ"/>
                </a>
            </div>
            <div>
                <H4>GoogleMap</H4>
                <Gmap
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3263.542765988083!2d136.75344161524347!3d35.1181265803294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60039c70523de2cd%3A0xa608a7d9dd4fd56c!2z44GN44Gj44Gh44KT44GZ44Ga5pyo!5e0!3m2!1sja!2sjp!4v1493990989725"
                    width="100%" height="320" frameBorder="0"
                    allowFullScreen/>
            </div>
        </Content>
    </Section>;
}

export default Access;

const Section = styled.div``

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    flex: 10 300px;
  }
`;

const Map = styled.img`
  width: 100%;
`;

const Gmap = styled.iframe`
  border: 0;
`;
import styled from "styled-components";
import OpeningHours from "./OpeningHours";
import FoodMenu from "./FoodMenu";
import Access from "./Access";
import color from "../styles/color";
import TakeOutMenu from "./TakeOutMenu";
import telImg from "../images/common/tel@3x.png"
import GlobalAlert from "./GlobalAlert";

function Main() {
    return <MainBase>
        <Wrap>
            <Content>
                <GlobalAlert/>
                <OpeningHours/>
                <Access/>
                <FoodMenu/>
                <TakeOutMenu/>
            </Content>
            <Contact src={telImg}/>
        </Wrap>
    </MainBase>;
}

export default Main;

const MainBase = styled.main`
  flex: 1 0;
  display: flex;
  justify-content: center;
`

const Wrap = styled.div`
  max-width: 800px;
`

const Content = styled.div`
  flex: 1 0;
  padding: 10px;
  margin: 0;
  @media (min-width: 640px) {
    padding: 20px;
    margin: 20px;
  }
  background-color: ${color.white};
`

const Contact = styled.img`
  display: block;
  margin: 0 auto;
  padding: 20px;
  max-width: 514px;
  box-sizing: border-box;
  object-fit: cover;
  width: 100%;
`
import styled from "styled-components"
import {wood_gradient} from "../styles/global";
import color from "../styles/color";

function Footer() {
    return <Base>
        <Copyright>
            © 2021 とんかつすず木
        </Copyright>
    </Base>;
}

export default Footer;

const Base = styled.footer`
  ${wood_gradient}
  padding-top: 12px;
  padding-bottom: 12px;
  color: ${color.white};
`

const Copyright = styled.div`
  text-align: center;
`